import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [income, setIncome] = useState('...');
  const [lastUpdate, setLastUpdate] = useState('');

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/income/indicator`)
      .then(response => {
        const data = response.data.data;
        const formattedIncome = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.value);
        setIncome(formattedIncome);

        // Format last update date and time
        const updateDate = new Date(data.lastUpdate);
        const formattedDateTime = updateDate.toLocaleString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
        setLastUpdate(formattedDateTime);
      })
      .catch(error => {
        console.error('There was an error fetching the data:', error);
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src="/assets/img/brand.png" alt="brand" />
        <p>Receita do DF {income}</p>
        <p><small>🗓️ {lastUpdate.replace(',', ' 🕐')}</small></p>
      </header>
    </div>
  );
}

export default App;
